<script>
// Moment

export default {
  name: 'BookingForm',
}
</script>

<template>
  <div class="flex justify-center" />
</template>
